import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ModalControl from '../../components/Controls/ModalControl';
import MS_Footer from '../../components/Footer/MS_Footer';
import MS_Header from '../../components/Header/MS_Header';
import '../../scss/login.scss';

class SiteDown extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='app'>
                <ModalControl ref={this.modal} />
                <MS_Header />
                <div className='app-body'>
                    <main className='main'>
                        <Container>
                            <Row className='row-center'>
                                <Col className='col-lg-4 col-md-3 col-2' />
                                <Col className='col-lg-4 col-md-6 col-8'>
                                    <Row className='row-padding logon'>
                                        <Col className='ms-header text-center'>
                                            Coming Soon:
                                            <br />
                                            An Even Better Experience
                                        </Col>
                                    </Row>
                                    <Row className='row-padding'>
                                        {
                                            <Col className='ms-text text-center'>
                                                We are updating the platform and will be back online
                                                <br />
                                                shortly. Thank you for your patience!
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col className='col-lg-4 col-md-3 col-2' />
                            </Row>
                            <Row className='row-center'>
                                <Col className={'col-lg-4 col-md-3 col-2'} />
                                <Col className={'col-lg-4 col-md-6 col-8'}></Col>
                                <Col className={'col-lg-4 col-md-3 col-2'} />
                            </Row>
                        </Container>
                    </main>
                </div>
                <MS_Footer />
            </div>
        );
    }
}

export default SiteDown;
