import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import AppContext from '../../AppContext';
import FormControl from '../../components/Controls/FormControl';
// import ModalControl from "../../../Controls/ModalControl";
// import InputControl from "@AFS-Development/ui-components"
import axiosHttpClient from '../../constants/axios-http-client';

export const DEFAULT_QUERY_FORGOTPASSWORD = 'Login/ForgotPassword';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.data = {
            message1:
                'Deferredcomp.morganstanleyatwork.com is now part of the Morgan Stanley at Work suite of workplace financial solutions.  Please enter your User ID and password to access your benefits.'
        };

        this.UserUpdateData = {
            username: '',
            password: ''
        };

        this.confirmationMessage = '';

        this.form = React.createRef();
        this.buttonRef = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.displayMessage = this.displayMessage.bind(this);
    }

    static contextType = AppContext;

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.buttonRef.current.focus();
            this.handleSubmit();
        }

        // if (event.key === 'Tab') {
        //   event.preventDefault();
        //   const { target } = event;
        //   const tabIndex = parseInt(target.getAttribute('tabIndex'));

        //   const nextElement = document.querySelector(`[tabIndex="${tabIndex + 1}"]`);

        //   if (nextElement) {
        //     nextElement.focus();
        //   }
        // }
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props || nextState !== this.state) {
            return true;
        }
        return false;
    }

    displayMessage(typeMessage, message) {
        this.form.current.togglemodalOK(typeMessage, message);
    }

    handleSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }

        let userValues = this.form.current.getModifiedValues(false);
        let msg = this.form.current.validateForm();
        if (msg != '') {
            this.displayMessage('Failed Validation', msg);
            return;
        }

        // TODO: HLM Sprint 2 check with Ricardo encription!
        let data = {
            token: this.context.encrypt('username=' + userValues.username)
        };
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        axiosHttpClient
            .all([axiosHttpClient.post(this.context.API_URL_HOST + DEFAULT_QUERY_FORGOTPASSWORD, data, axiosConfig)])
            .then(
                axiosHttpClient.spread((resp) => {
                    if (resp.data.errorMessage != 0) {
                        this.displayMessage('Failed Validation', resp.data.msgError);
                    } else {
                        this.props.changeMenuIndex('Login', resp.data.returnMessage);
                    }
                })
            )
            .catch((error) => {
                console.log(error);
                this.props.displayError(0, '', error);
            });
    }

    render() {
        return (
            <div className='app-body'>
                <main className='main'>
                    <Container>
                        {/* <Row className="row-center">Log On</Row> */}
                        <Row className='row-center'>
                            <Col className='col-lg-4 col-md-3 col-2' />
                            <Col className='col-lg-4 col-md-6 col-8'>
                                <Row className='row-padding logon'>
                                    <Col className='ms-header text-center'>Reset your password</Col>
                                </Row>
                                <Row className='row-padding'>
                                    <Col className='ms-text text-center'>To begin changing your password, please enter your user name.</Col>
                                </Row>
                                <FormControl
                                    ref={this.form}
                                    name={'frmUserUpdate'}
                                    className1={'deferral-label2'}
                                    className2={''}
                                    classNameRow1={'row-paddingtop'}
                                    classNameRow2={'row-nopadding'}
                                    initialStatus={'2'}
                                    displayError={this.props.displayError}
                                    data={this.UserUpdateData}
                                    hideRequiredMark={true}
                                    display={[
                                        {
                                            tag: 'RowField2',
                                            className: 'deferral-field2',
                                            field: 'username',
                                            fieldType: 'text',
                                            title: 'User Name',
                                            required: true
                                        }
                                    ]}
                                />
                            </Col>
                            <Col className='col-lg-4 col-md-3 col-2' />
                        </Row>
                        <Row className='row-center'>
                            <button
                                ref={this.buttonRef}
                                className='btn btn-secondary ms-logon-btn btn-save-ms '
                                hidden={false}
                                onClick={() => {
                                    this.handleSubmit();
                                }}
                                data-testid='resetSubmit'
                            >
                                {'Submit'}
                            </button>
                        </Row>
                        <Row className='mt20 mb20'>
                            <Col className='text-center'>
                                <Button
                                    className='btn btn-cancel-ms'
                                    onClick={() => this.props.changeMenuIndex('Login')}
                                    data-testid='cancelReset'
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </div>
        );
    }
}

export default ResetPassword;
