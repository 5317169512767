import moment from 'moment';
import React, { Component } from 'react';
import AppContext from '../../AppContext';
import ModalControl from '../../components/Controls/ModalControl';
import MS_Footer from '../../components/Footer/MS_Footer';
import MS_Header from '../../components/Header/MS_Header';
import ContactUs from './ContactUs';
import ErrorPage from './ErrorPage';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';

class Full extends Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0
        };

        this.modal = React.createRef();
        this.displayError = this.displayError.bind(this);
        this.changeMenuIndex = this.changeMenuIndex.bind(this);
        this.emailMask = '';
        this.state = {
            firstTime: false,
            forcePaint: false,
            currentPage: 'Login',
            bannerText: ''
        };
    }

    changeMenuIndex(page, emailMask) {
        if (emailMask !== undefined) {
            this.emailMask = emailMask;
        }
        this.setState({
            currentPage: page
        });
        //this.updateDimensions();
        //window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        const footerElement = document.getElementsByTagName('footer')[0];
        const totalHeight =
            footerElement.offsetHeight +
            parseInt(window.getComputedStyle(footerElement).getPropertyValue('padding-top')) +
            parseInt(window.getComputedStyle(footerElement).getPropertyValue('padding-bottom')) +
            parseInt(window.getComputedStyle(footerElement).getPropertyValue('margin-top')) +
            parseInt(window.getComputedStyle(footerElement).getPropertyValue('margin-bottom'));
        this.setState({ height: totalHeight });
    }

    componentDidMount() {
        this.setState({ firstTime: true });
        const hostname = top.location.hostname;
        if (hostname != 'localhost') this.getBanner();

        //this.updateDimensions();
        //window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    getBanner() {
        fetch('/banner/bannerConfig.json')
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then((response) => {
                this.setMigrationBanner(response.banners);
            });
    }

    setMigrationBanner = (banners) => {
        const currentDate = moment.utc();

        const currentBanner = banners?.find((banner) => {
            const startDate = moment.utc(banner.startDate);
            const endDate = moment.utc(banner.endDate).endOf('day');
            return currentDate.isBetween(startDate, endDate, null, '[]');
        });

        if (currentBanner?.text) this.setState({ bannerText: currentBanner.text });
    };

    static contextType = AppContext;

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props || nextState !== this.state) {
            if (nextState.firstTime == true) {
                this.setState({ firstTime: false });
            }
            return true;
        }
        return false;
    }

    displayError(msgError, errorMessage, axiosError, okFunction) {
        if (axiosError !== undefined) {
            console.log(axiosError);
            this.modal.current.togglemodalOK('Error', 'Unexpected Error');
        } else {
            this.modal.current.togglemodalOK('Message', msgError, severity, okFunction);
        }
        return;
    }

    render() {
        if (this.props.action == 'serror') {
            return (
                <div className='app'>
                    <MS_Header />
                    <ErrorPage />
                    <MS_Footer />
                </div>
            );
        }
        if (this.state.currentPage == 'Login') {
            return (
                <div className={`app ${this.state.bannerText ? 'app-migration-banner' : ''}`}>
                    <ModalControl ref={this.modal} />
                    <MS_Header bannerText={this.state.bannerText} />
                    <LoginForm
                        height={this.state.height}
                        action={this.props.action}
                        displayError={this.displayError}
                        changeMenuIndex={this.changeMenuIndex}
                        emailMask={this.emailMask}
                        data={this.props.contactInfo}
                    />
                    <MS_Footer />
                </div>
            );
        } else if (this.state.currentPage == 'ResetPassword') {
            return (
                <div className='app'>
                    <ModalControl ref={this.modal} />
                    <MS_Header />
                    <ResetPassword displayError={this.displayError} changeMenuIndex={this.changeMenuIndex} />
                    <MS_Footer />
                </div>
            );
        } else if (this.state.currentPage == 'ContactUs') {
            return (
                <div className='app'>
                    <ModalControl ref={this.modal} />
                    <MS_Header />
                    <ContactUs displayError={this.displayError} changeMenuIndex={this.changeMenuIndex} data={this.props.contactInfo} />
                    <MS_Footer />
                </div>
            );
        }
    }
}

export default Full;
