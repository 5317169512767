import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const ErrorPage = () => {
    return (
        <div className='app-body'>
            <Container>
                <Row className='mt30 ms-text text-center'>
                    <Col className='ms-text text-center'>
                        <b>Contact your administrator for more information.</b>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorPage;
