import React from 'react';
import { Input, InputGroup } from 'reactstrap';

class CheckBoxControl extends React.Component {
    constructor(props) {
        super(props);
        let integerValue = props.integerValue != undefined ? props.integerValue : false;
        let initialValue = props.value;
        if (integerValue) initialValue = props.value == -1 ? true : false;
        this.state = {
            isChecked: initialValue
        };
        this.handleChange = this.handleChange.bind(this); // set this, because you need get methods from CheckBox
    }

    handleChange() {
        let integerValue = this.props.integerValue != undefined ? this.props.integerValue : false;
        let onChange = this.props.onChange != undefined ? this.props.onChange : null;
        if (onChange != null) {
            let newValue = !this.state.isChecked;
            if (integerValue) newValue = !this.state.isChecked ? -1 : 0;
            onChange(this.props.name, newValue, null, null, this.props.subField);
        }
        this.setState({ isChecked: !this.state.isChecked });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props || nextState !== this.state) {
            if (this.props.value != nextProps.value && nextState.isChecked != nextProps.value) {
                this.setState({ isChecked: nextProps.value });
                return false;
            }
            return true;
        }
        return false;
    }

    render() {
        return (
            <InputGroup className={this.props.wrapClassName} data-testid='inputGroup'>
                <Input
                    type='checkbox'
                    id={this.props.id}
                    name={this.props.name}
                    data-testid='input'
                    disabled={this.props.disabled != undefined ? this.props.disabled : false}
                    onChange={this.handleChange}
                    className={this.props.className}
                    checked={this.state.isChecked}
                />
                <div className='checklabel'>{this.props.text}</div>
            </InputGroup>
        );
    }
}
export default CheckBoxControl;
