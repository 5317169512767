import MockAdapter from 'axios-mock-adapter';
import connectErrorData from '../assets/mock-data/connect-error.json';
import connectSuccessData from '../assets/mock-data/connect-success.json';
import forgotPasswordSuccessData from '../assets/mock-data/forgot-password-success.json';
import contactSuccessData from '../assets/mock-data/getcontact-success.json';

export const isMockEnabled = () => {
    return process.env.REACT_APP_MOCK_ENABLED === 'true';
};

export const initializeAxiosMockAdapter = (instance) => {
    const mock = new MockAdapter(instance);
    mock.onGet(new RegExp('/Login/Connect/*')).reply((config) => loginFailureIfNotCbDemille(config));
    mock.onPost(new RegExp('/Login/GetContact/*')).reply(() => getContactSuccess());
    mock.onGet(new RegExp('/Login/forgot_password/*')).reply(() => getForgotPasswordSuccess());
};

export const loginFailureIfNotCbDemille = (config) => {
    const url = new URL(config.url);
    if (url.searchParams.get('username') === 'cb demille') {
        return getConnectSuccess();
    }
    return getConnectError();
};

export const getConnectSuccess = () => {
    return [200, connectSuccessData];
};

export const getConnectError = () => {
    return [200, connectErrorData];
};

export const getContactSuccess = () => {
    return [200, contactSuccessData];
};

export const getForgotPasswordSuccess = () => {
    return [200, forgotPasswordSuccessData];
};
