import React from 'react';
import { NumericFormat } from 'react-number-format';

class FormatNumberControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props || nextState !== this.state) {
            if (nextProps.value != this.props.value) {
                this.setState({ value: nextProps.value });
                return false;
            }
            return true;
        }
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <NumericFormat
                    value={this.state.value}
                    data-testid='numeric'
                    displayType={this.props.displayType}
                    fixedDecimalScale={this.props.fixedDecimalScale}
                    decimalScale={this.props.decimalScale}
                    thousandSeparator={this.props.thousandSeparator}
                    prefix={this.props.prefix}
                    suffix={this.props.suffix}
                />
            </React.Fragment>
        );
    }
}

export default FormatNumberControl;
