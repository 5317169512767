// import {
//   faChevronCircleUp,
//   faChevronCircleDown,
//   faEye,
//   faEyeSlash,
// } from "@fortawesome/pro-light-svg-icons";
import { Icon } from '@morgan-stanley/campfire-client-react';
import React from 'react';
import { Input, InputGroup } from 'reactstrap';

class PasswordInputControl extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        // this.escFunction = this.escFunction.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.changeShow = this.changeShow.bind(this);
        this.state = {
            value: props.value,
            lastValue: props.value,
            errorMessage: '',
            hiddenMessage: props.displayError != undefined,
            isShow: false
        };
    }

    validatePassword(value, err) {
        if (this.props.required != undefined && this.props.required == true && value == null) {
            err.msg = 'This field can not be empty, please enter a valid password.';
            return false;
        }
        if (value.length < this.props.minNumberCharacter) {
            err.msg = 'Password must have at least 8 characters';
            return false;
        }

        if (value.length > this.props.maxNumberCharacter) {
            err.msg = 'Password must have at must 20 characters';
            return false;
        }

        let lettersReg = /[a-zA-Z]/;
        let numbersReg = /\d/;

        if (!lettersReg.test(value)) {
            err.msg = 'Password must have at least 1 letter';
            return false;
        }
        if (!numbersReg.test(value)) {
            err.msg = 'Password must have at least 1 number';
            return false;
        }
        let specialCharactersReg = /[~`!@#$%^&*()+=|\\{}':;.,<>/?[\]""_-]/;
        if (specialCharactersReg.test(value)) {
            err.msg = 'Password must have only letters or numbers';
            return false;
        }

        /* else if (
       (this.props.required === undefined || this.props.required == false) &&
       value == null
     ) {
       return true;
     }   */

        return true;
    }

    handleChange(event) {
        let msg = '';
        if (event.target.value.length > 1 && this.state.value == '') {
            this.handleBlur(event);
        }
        this.setState({ value: event.target.value });
    }

    handleBlur(event) {
        let err = { msg: '' };
        let newValue = event.target.value;

        if (event.target.value != this.state.lastValue) {
            let isValidPassword =
                this.props.validationRequired ||
                (this.props.validationRequired === undefined ? this.validatePassword(newValue, err) : true);
            if (isValidPassword) {
                if (this.props.onChange != undefined) {
                    let ret = this.props.onChange(this.props.name, event.target.value, this.state.lastValue, err, this.props.subField);
                    if (ret === -1) {
                        this.setState({ value: this.state.lastValue });
                    } else if (ret === true) {
                        this.setState({ lastValue: event.target.value });
                    } else {
                        event.target.focus();
                        //this.setState({value: this.state.lastValue});
                    }
                } else {
                    this.setState({ lastValue: event.target.value });
                }
            } else {
                if (this.props.displayError != undefined) {
                    this.props.displayError(this.props.name, newValue, err.msg);
                }
                event.target.focus();
                //this.setState({value: this.state.lastValue});
            }
        }
        if (!this.state.hiddenMessage) {
            this.setState({ errorMessage: err.msg });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props || nextState !== this.state) {
            if (nextProps.value !== this.props.value) {
                this.setState({ value: nextProps.value, lastValue: nextProps.value });
                return false;
            }
            if (nextProps.displayError != this.props.displayError) {
                this.setState({ hiddenMessage: props.displayError != undefined });
                return false;
            }
            return true;
        }
        return false;
    }

    handleKeyDown(event, init_value) {
        if (event.keyCode === 27) {
            this.setState({
                value: init_value
            });
        }
    }
    changeShow() {
        this.setState({
            isShow: !this.state.isShow
        });
    }

    render() {
        let maxLength = this.props.maxLength;
        if (maxLength === undefined) maxLength = 20;
        let placeholder = this.state.placeholder;
        if (placeholder === undefined) placeholder = '';
        return (
            <InputGroup className={this.props.wrapClassName}>
                {this.props.icon != undefined && (
                    <div className={'input-group-prepend input-group-text ' + this.props.iconClassName}>
                        <Icon name={this.props.icon} />
                    </div>
                )}
                {this.props.prefix != undefined && <React.Fragment>{this.props.prefix}</React.Fragment>}
                <Input
                    type={this.state.isShow ? 'text' : 'password'}
                    id={this.props.id}
                    name={this.props.name}
                    className={this.props.className}
                    disabled={this.props.disabled != undefined ? this.props.disabled : false}
                    placeholder={placeholder}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onKeyUp={(e) => this.handleKeyDown(e, this.state.lastValue)}
                    onBlur={(e) => this.handleBlur(e)}
                    maxLength={maxLength}
                    required={this.props.required}
                />
                {this.props.hasShowIcon !== undefined &&
                    this.props.hasShowIcon == true && ( //&&
                        //this.props.tag != "RowField2"

                        <a
                            data-testid='showOrHideElement'
                            className='message-link'
                            onClick={() => {
                                this.changeShow();
                            }}
                        >
                            {' '}
                            {this.state.isShow ? 'Hide' : 'Show'}
                            {/* <Icon
                name={this.state.isShow ? faEyeSlash : faEye}
                size="md"
              /> */}
                        </a>
                    )}
                {this.props.suffix != undefined && <React.Fragment>{this.props.suffix}</React.Fragment>}
                {this.props.iconpost != undefined && (
                    <div className={'input-group-prepend input-group-text ' + this.props.iconClassName}>
                        <Icon name={this.props.iconpost} />
                    </div>
                )}
                {this.props.requiredMark != undefined && this.props.requiredMark && <font color='red'>{'\u00A0'}*</font>}
                <div hidden={this.state.hiddenMessage} className='col-form-label text-red'>
                    {this.state.errorMessage}
                </div>
            </InputGroup>
        );
    }
}

export default PasswordInputControl;
